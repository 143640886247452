import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const settings = {
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    className: "center",
    centerMode: true,
    centerPadding: "20px",
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
        {
          breakpoint: 1480,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 1240,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 940,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]

  };
  

function About() {

  return (
    <div>
  
  <h2 style={{textAlign:'center',marginTop:'30px',backgroundColor:'#CB831E',margin:'0px 8%'}}>Agromax Warehousing’s offerings</h2>

  <div className="cards"
  style={{
    position: 'relative',
    margin: '20px 8%',

  }}>

      <Slider {...settings}>
      <div style={{
        padding:20,
        background: 'white',
        color:'black',
        margin:'20px 25px',
        borderRadius:8,
        overflow:"hidden",
        width:250,
        fontSize:14,
        boxShadow:'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'
    }}>
        <div style={{display:"grid",placeItems:"center"}}>
        <img style={{
            height:250,
            width:250,
             border:'4px solid #CB7B0E',
        }} src='img/t.jpeg' alt='img'/>
        </div>
       <div style={{padding:'10px 20px 20px 20px'}}> 
        <h2 style={{margin:0}}>Experienced & Trained Manpower</h2>
        <p>Our team consists of highly experienced and rigorously trained professionals.</p>
        </div>
   
    </div>


    <div  style={{
        background: 'white',
        color:'black',
        margin:'20px 25px',
        borderRadius:8,
        width:250,
        fontSize:14,
        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
       
             border:'4px solid #CB7B0E'
    }}>
        <div style={{display:"grid",placeItems:"center"}}>
        <img style={{
            width:250,height:250,
             border:'4px solid #CB7B0E'
        }} src='cctv.jpeg' alt='img'/>
        </div>
        <div style={{padding:25}}>
        <h2 style={{margin:0}}>24*7 Surveillance</h2>
        <p>Continuous monitoring to ensure safety and security around the clock.</p>
        </div>
        
    </div>


    <div style={{
        background: 'white',
        color:'black',
        margin:'20px 25px',
        borderRadius:8,
        width:250,
        fontSize:14,
        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'
    }}>
        <div style={{display:"grid",placeItems:"center"}}>
        <img style={{
            width:250,height:250,
             border:'4px solid #CB7B0E'
        }} src='img/s.jpeg' alt='img'/>
        </div>

        <div style={{padding:'10px 20px 20px 20px'}}>
        <h2 style={{margin:0}}>Strict Security</h2>
        <p>Adherence to stringent security protocols to safeguard your assets.</p>
        </div>
        
    </div>


    <div style={{
        background: 'white',
        color:'black',
        margin:'20px 25px',
        borderRadius:8,
        width:250,
        fontSize:14,
        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'
    }}>
        <div style={{display:"grid",placeItems:"center"}}>
        <img style={{
            width:250,height:250,
             border:'4px solid #CB7B0E'
        }} src='img/p.jpeg' alt='img'/>
        </div>

        <div style={{padding:'10px 20px 20px 20px'}}>
        <h2 style={{margin:0}}>Prompt Service</h2>
        <p>	Quick and efficient response to all your needs, ensuring minimal downtime.</p>
        </div>
        
    </div>

    <div style={{
        background: 'white',
        color:'black',
        margin:'20px 25px',
        borderRadius:8,
        width:250,
        fontSize:14,
        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'
    }}>

<div style={{display:"grid",placeItems:"center"}}>

        <img style={{
            width:250,height:250,
             border:'4px solid #CB7B0E'
        }} src='img/l.jpeg' alt='img'/>
    </div>

        <div style={{padding:'10px 20px 20px 20px'}}>
        <h2 style={{margin:0}}>Logistics and Distribution</h2>
        <p>Comprehensive services to handle the transportation and distribution of your products.</p>
        </div>
        
    </div>
      
      </Slider>
    </div>






    <div  className='m0' style={{margin:'20px 8%',textAlign:"center",gap:20}}>
        <div className="client" style={{ borderRadius:10,background:'#aeaeae24',padding:'4vw'}}>
            <h2 >Our Corporate Clients</h2>
            <div style={{display:"flex",alignItems:"center",justifyContent:'space-around',flexWrap:"wrap",gap:20,padding:'2vw'}}>
                <img data-aos="flip-left" src="https://pbs.twimg.com/profile_images/1737010598645530624/_Ucbqzf7_400x400.jpg" alt="img" width={150}  />
                <img data-aos="flip-left" src="https://media.licdn.com/dms/image/D4D0BAQHr43129qzFnA/company-logo_200_200/0/1666588056003?e=2147483647&v=beta&t=uJVsTTnnEvXsgO5Cm48gPhjNPCD-CBYu7HOP4o1ICZk" alt="img" width={150}  />
                {/* <img data-aos="flip-left" src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/ff/ITC_Limited_Logo.svg/1200px-ITC_Limited_Logo.svg.png" alt="img" width={150}  /> */}
                <img data-aos="flip-left" src="https://1000logos.net/wp-content/uploads/2023/02/Cargill-logo.png" alt="img" width={150}  />
                {/* <img data-aos="flip-left" src="https://www.livelaw.in/h-upload/2019/02/04/1600x960_britania.jpg" alt="img" width={150}  /> */}
            </div>
         </div>
    </div>

    <div className='m0' style={{margin:'20px 8%',textAlign:"center",gap:20}}>
        <div className="client" style={{ borderRadius:10,background:'#aeaeae24',padding:'4vw'}}>
            <h2 >OUR TIE-UPS WITH NBFCS </h2>
            <div style={{display:"flex",alignItems:"center",justifyContent:'space-around',flexWrap:"wrap",gap:20,padding:'2vw'}}>
                <img data-aos="flip-left" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSfdSZvVVEUqyHwWdi-zBNEyse_-YUtEFrzqA&s" alt="img" width={250}  />
                <img data-aos="flip-left" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQS2xNoAlcdssSUDwrvZMHNHWj8VsZZb9dmkA&s" alt="img" width={290}  />
                <img data-aos="flip-left" src="https://m.economictimes.com/thumb/msid-107102448,width-1600,height-900,resizemode-4,imgsize-29754/lt-finance-logo-jpg-3-1.jpg" alt="img" width={150}  />

            </div>
         </div>
    </div>


    </div>
  )
}

export default About
