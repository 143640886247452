import React,{useEffect, useState} from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const colors = ["#0088FE", "#00C49F", "#FFBB24"];
const delay = 5000;

function Navbar(props) {
    const [IsOpen, setIsOpen] = useState(false)
    const toggleNavbar = () => {
        setIsOpen(!IsOpen);
      };


const [Action, setAction] = useState('0')
      const click =(e)=>{
        if (e==='v') {
            setAction(Action==='1'?'0':'1')
            props.setx(Action==='1'?'0':'1')
            
        }else if (e==='m') {
            
            setAction(Action==='2'?'0':'2')
            props.setx(Action==='2'?'0':'2')
        }else if (e==='p') {
            
            setAction(Action==='3'?'0':'3')
            props.setx(Action==='3'?'0':'3')
        }else{
            setAction('0')
        }
      }



      

    



 return (
        <div>
            <ToastContainer />
    <nav className="mobile" style={{
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        padding:'0px 8%',
        background: 'linear-gradient(90deg, rgba(80,40,0,1) 0%, rgba(213,123,0,1) 53%, rgba(255,172,0,1) 100%)',
        color:'white',
        height:65,
        fontSize:14,
        borderBottom:'1px solid '
    }}>
        <div style={{
            display:'flex',
            justifyContent:'left',
            alignItems:'center',
            gap:25
        }}>
            <h1 style={{margin:'0px 10px 0px 0px',alignItems:'center',display:'flex'}}><img style={{width:40,marginRight:5,borderRadius:15}} src='flogo.png' alt='img'/>Agromax</h1>
        </div>
        <div>
           {IsOpen?<i onClick={Action==='0'?toggleNavbar:()=>setAction('0')} data-target='slide-up' className="fa fa-xmark"/>:
            <i onClick={toggleNavbar} data-target="slide-down" className="fa fa-bars"/>}
        </div>
      
    </nav>

    {IsOpen&&<div className='navbar' data-target="slide-content" >
           <div style={{textAlign:"center",color:"white",padding:10, background: 'linear-gradient(90deg, rgba(80,40,0,1) 0%, rgba(213,123,0,1) 53%, rgba(255,172,0,1) 100%)'}} >
           <div onClick={()=>window.location.reload()} >
            <i style={{
                margin:5,padding:3
            }} class="fa-solid fa-house"></i>
            <a>Home</a>
            </div>
            <div onClick={()=>{props.scroll('About');click('kk')}}>
            <i style={{
                margin:5,padding:3
            }} class="fa-solid fa-address-card"></i>
            <a>About</a>
            </div>
            <div onClick={()=>click('v')}>
            <i style={{
                margin:5,padding:3
            }} class="fa fa-group"></i>
            <a>Vision</a>
            </div> 
            
            <div onClick={()=>click('m')}>
            <i style={{
                margin:5,padding:3
            }} class="fa fa-bookmark"></i>
            <a>Mission</a>
            </div> 
            <div onClick={()=>{props.scroll('Project');click('kk')}}>
            <i style={{
                margin:5,padding:3
            }} class="fa fa-file"></i>
            <a>Project</a>
            </div>

            <div onClick={()=>click('p')}>
            <i style={{
                margin:5,padding:3
            }} class="fa-solid fa-hand-holding-droplet"></i>
            <a>Services</a>
            </div>

            {/* <div onClick={()=>toast("Coming Soon...")}>
            <i style={{
                margin:5,padding:3
            }} class="fa-solid fa-blog"></i>
            <a>Blog</a>
            </div> */}
            <div style={{margin:5,padding:3}}>
               <a>Call Us  +91- 93407 64314</a>
            </div>
            <div style={{margin:10,padding:3}} onClick={()=>{props.scroll('Contactus');click('kk')}}>
            <a style={{
                borderRadius:8,
                border:'1px solid white',
                padding:'8px 12px',
            }}>Contact Us</a>
            </div>
    </div>
     </div>}
    


    <nav className="desktop"  style={{
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        padding:'0px 8%',
        background: 'linear-gradient(90deg, rgba(80,40,0,1) 0%, rgba(213,123,0,1) 53%, rgba(255,172,0,1) 100%)',
        color:'white',
        height:65,
        fontSize:14,
    }}>
        <div style={{
            display:'flex',
            justifyContent:'left',
            alignItems:'center',
            gap:25
        }}>
            <h1 onClick={()=>window.location.reload()} style={{margin:'0px 10px 0px 0px',alignItems:'center',display:'flex'}}><img style={{width:40,marginRight:5,borderRadius:15}} src='flogo.png' alt='img'/>Agromax</h1>

            <div onClick={()=>window.location.reload()}>
            <i style={{
                marginRight:5
            }} class="fa-solid fa-house"></i>
            <a>Home</a>
            </div>

            <div onClick={()=>{props.scroll('About');click('kk')}}>
            <i style={{
                marginRight:5
            }} class="fa-solid fa-address-card"></i>
            <a>About</a>
            </div>

       
          
            <div onClick={()=>click('v')}>
            <i style={{
                margin:5,padding:3
            }} class="fa fa-group"></i>
            <a>Vision</a>
            </div> 
            
            <div onClick={()=>click('m')}>
            <i style={{
                margin:5,padding:3
            }} class="fa fa-bookmark"></i>
            <a>Mission</a>
            </div> 
            

            {/* <div onClick={()=>scroll('Team')}>
            <i style={{
                margin:5,padding:3
            }} class="fa fa-group"></i>
            <a>Team</a>
            </div>  */}
            <div onClick={()=>click('p')}>
            <i style={{
                marginRight:5
            }} class="fa-solid fa-hand-holding-droplet"></i>
            <a>Services</a>
            </div>

            <div onClick={()=>{props.scroll('Project');click('kk')}}>
            <i style={{
                margin:5,padding:3
            }} class="fa fa-file"></i>
            <a>Project</a>
            </div>
        
           
            {/* <div onClick={()=>toast("Coming Soon...")}>
            <i style={{
                marginRight:5
            }} class="fa-solid fa-blog"></i>
            <a>Blog</a>
            </div> */}
        </div>

        <div>

            <div style={{
            display:'flex',
            justifyContent:'left',
            alignItems:'center',
            gap:25
        }}>
            <a>Call Us  +91- 93407 64314</a>
            <a onClick={()=>{props.scroll('Contactus');click('kk')}} style={{
                borderRadius:8,
                border:'1px solid white',
                padding:'10px 12px',
            }}>Contact Us</a>
            </div>
            
        </div>
      
    </nav>

<Show Action={Action} set={()=>setAction('0')}/>

    </div>

  )

}

const Show=(props)=>{


    const [currentSlide, setCurrentSlide] = useState(0);
    const [slides, setSlides] = useState([
      
      {url:'simg/wr.jpeg', image: 'Comprehensive Warehousing Solutions', alt: 'Storage Facilities: Secure, well-maintained warehousing spaces designed for long-term and short-term storage of grains, pulses, oilseeds, and other agricultural commodities. Temperature-Controlled Warehousing: Specialized storage for perishable and sensitive commodities with temperature and humidity regulation. Customizable Storage Spaces: Flexible warehousing options to accommodate the unique storage needs of different clients.' },
      {url:'simg/fm2.jpeg', image: 'Fumigation and Pest Control', alt: 'Regular Fumigation: Scheduled fumigation services to ensure the protection of stored commodities against pests and infestations. Integrated Pest Management: Advanced pest monitoring and prevention systems to maintain a pest-free environment in the warehouse.' },
      {url:'simg/ivm.jpeg', image: 'Inventory Management', alt: 'Real-Time Stock Tracking: Automated systems to monitor inventory in real-time, ensuring accurate data on the movement of goods. First-In, First-Out (FIFO) Systems: Effective inventory rotation to minimize wastage and optimize storage efficiency. Customized Reporting: Detailed inventory reports generated to help clients keep track of their stock with ease.' },
      {url:'simg/cm.jpeg', image: 'Collateral Management Services', alt: 'Collateral Financing Support: Collaborations with financial institutions to provide collateral-based loans using stored goods as security. Warehouse Receipts: Issuance of warehouse receipts for easy financing and collateralization of stored goods.' },
      {url:'simg/lg.jpeg', image: '⁠Logistics and Supply Chain Solutions', alt: 'End-to-End Logistics: Comprehensive logistics support including transportation, loading/unloading, and delivery services. Distribution Network Management: Seamless supply chain solutions connecting farmers, traders, processors, and markets. Efficient Transport Services: A fleet of trucks and distribution systems to ensure timely delivery of goods across regions.' },
      {url:'simg/qt.jpeg', image: 'Quality Control and Commodity Grading', alt: 'Grading and Sorting: Commodity grading services to ensure products meet the required market standards. Lab Testing: In-house quality assessment of moisture content, nutritional levels, and contaminants in stored commodities. Inspection Services: Regular inspections and quality checks to ensure compliance with safety and quality standards.' },
      {url:'simg/ewr.jpeg', image: '⁠e-Warehouse Receipts', alt: 'Digital Warehouse Receipts: Safe and secure electronic warehouse receipts that streamline inventory management and financing. Transparency and Security: Digital records ensure transparency and provide easy access for clients to manage their stocks.' },
      {url:'simg/cs.jpeg', image: '⁠Consultancy Services', alt: 'Warehouse Management Consultation: Expert consultancy on setting up and managing warehouse operations, logistics, and inventory systems. Sustainable Agricultural Consultancy: Providing advice on sustainable agricultural practices and supply chain optimization' },
      {url:'simg/tr.jpeg', image: 'Training and Capacity Building', alt: 'Workshops and Training: Regular training sessions for farmers, traders, and employees on best practices in warehousing and supply chain management. Farmer Support Programs: Educational programs and support services for Farmer Producer Organizations (FPOs) to help smallholder farmers aggregate their produce.' },
      {url:'simg/fpo2.jpeg', image: 'Farmer Producer Organization (FPO) Support', alt: 'FPO Storage Solutions: Affordable and tailored storage solutions for FPOs to assist with aggregation, quality control, and inventory management.Advisory Services for FPOs: Consultancy and advisory services aimed at helping FPOs improve their warehousing and logistics operations.Why Choose Agromax Warehousing Pvt Ltd? Experienced Team: Highly trained professionals with years of expertise in warehousing, logistics, and agricultural supply chain management. Advanced Infrastructure: State-of-the-art facilities with the latest technology for storage, security, and inventory tracking. Client-Focused Approach: Customized services to meet the specific needs of clients, from smallholder farmers to large agribusinesses. Nationwide Presence: Warehouses located across 10 states, offering comprehensive coverage and accessibility for clients across India.' },
      {url:'simg/mc.jpeg', image: 'Micro Warehousing', alt: 'We offer flexible micro warehousing solutions tailored to small-scale farmers and local businesses, ensuring that you have easy access to storage close to your market.' },
      {url:'simg/ivm.jpeg', image: 'Training and Capacity Building', alt: 'Our farmgate services facilitate direct storage solutions for farmers, allowing them to store their produce immediately after harvest, reducing spoilage and enhancing market readiness.' },

      {url:'simg/ewr.jpeg', image: 'Professional Warehousing (PWH)', alt: 'Professional Warehousing provides secure, efficient, and scalable storage solutions for various types of goods. Key features may include climate control, advanced security systems, inventory management, and real-time tracking.' },
      {url:'simg/wr.jpeg', image: 'Warehouse Management Services (WMS)', alt: 'Warehouse Management Services involve the systematic control of warehouse operations. This includes receiving, storing, and shipping goods, optimizing warehouse layout, managing inventory levels, and using warehouse management software (WMS) to enhance operational efficiency.' },
      {url:'simg/au.jpeg', image: 'Audit Outsourcing Services', alt: 'Audit Outsourcing Services offer external audit solutions to ensure compliance with regulatory standards, optimize operational processes, and identify areas for improvement. These services might cover financial audits, compliance audits, and operational audits.' },
      {url:'simg/nabl.jpeg', image: 'NABL Accredited Commodity Testing Lab', alt: 'A NABL Accredited Commodity Testing Lab provides precise and reliable testing and certification services for agricultural and other commodities. Accreditation by the National Accreditation Board for Testing and Calibration Laboratories (NABL) ensures adherence to international standards, enhancing the credibility and acceptance of test results.' },
      {url:'simg/ag.jpeg', image: 'Agri Commodity Procurement Services', alt: 'Agri Commodity Procurement Services assist in sourcing agricultural products directly from farmers or other suppliers. These services aim to ensure the procurement of high-quality commodities at competitive prices, manage supply chain logistics, and maintain inventory levels according to market demand.' }

      // Add more slides as needed
    ]);
  
    useEffect(() => {
      const intervalId = setInterval(() => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
      }, 3000); // Adjust the interval time to fit your needs
  
      return () => clearInterval(intervalId);
    }, [slides]);
  

    return(
        <div onClick={()=>props.set()} className='show' style={{
            display:props.Action==="0"?'none':"block",
            position:'absolute',
            top:65,
            left:0,
            right:0,
            height:props.Action!=='0'?500:200,
            background: 'linear-gradient(90deg, rgba(80,40,0,1) 0%, rgba(213,123,0,1) 53%, rgba(255,172,0,1) 100%)',
            overflow:"auto",
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'
        }}>

           {props.Action==='1'&& <>
           <h2 data-aos="flip-left" style={{color:'white',textAlign:'center',margin:50}}>OUR VISION</h2>
           <ul data-aos="flip-left" className='fc' style={{display:'flex',gap:20,margin:20,color:'white'}}>
                <li>Excellence in Service Delivery: Agromax Warehousing aspires to be a leading collateral management service provider by consistently delivering exceptional service and innovative solutions that meet and exceed industry standards.
                </li>
                <li>Integrity and Trust: Agromax Warehousing is committed to upholding the highest standards of integrity, ensuring transparent and trustworthy relationships with clients, partners, and stakeholders.
                </li>
                <li>Industry Transformation: Agromax Warehousing aims to be a transformative force in the collateral management sector, pioneering advancements and setting new benchmarks that elevate the entire industry.
                </li>
            </ul>
            </>}

            {props.Action==='2'&& <>
           <h2 data-aos="flip-left" style={{color:'white',textAlign:'center',margin:60}}>OUR MISSION</h2>
           <ul data-aos="flip-left" className='fc' style={{display:'flex',gap:20,margin:20,color:'white'}}>
                <li>Comprehensive Warehousing Solutions: Deliver unparalleled, end-to-end warehousing solutions tailored to meet the diverse needs of our clients.</li>
                <li>Supply Chain Optimization: Empower clients to optimize their supply chains through innovative and efficient warehousing practices.</li>
                <li>Risk Mitigation and Value Maximization: Assist clients in mitigating risks and maximizing value by providing reliable and strategic warehousing services.</li>
            </ul> 
            </>}

            {props.Action==='3'&& <>

            

                {/* <h2 style={{color:'white',textAlign:'center'}}>OUR SERVICE
                </h2> */}


                <div className="slider">
      <div className="slides" style={{
        transform: `translateX(${currentSlide * -100}%)`,
        transition: 'transform 0.5s ease-in-out',
      }}>
        {slides.map((slide, index) => (
          <div key={index} className="slide" style={{
            textAlign:'center',
            backgroundImage:`url(${slide.url})`,
            backgroundSize: 'cover',
            backgroundPosition:'center',
            backgroundRepeat:'no-repeat',
            width:'100%',
            height:500,
            color:'white',
            display:'flex',
            justifyContent:'center',
            alignItems:'end'
          }}>
            {/* <img data-aos="flip-left" style={{width:280,height:150}} src={slide.url} alt='img'/> */}
            <div style={{
                width:'80%',
                background:'#0000007d',
                borderRadius:8,
                boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
                marginBottom:20
            }}>

            <h1 data-aos="flip-left">{slide.image}</h1>
            <p data-aos="flip-left">{slide.alt}</p>
          </div>
          </div>
        ))}
      </div>
    </div>

              </>}

        </div>
    )
}



export default Navbar
