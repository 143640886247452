import React from 'react'

function Header(props) {


  if (props.Action!=='0') {
    return (
      <header className='header' style={{
          margin:'20px 8%',
          borderRadius:8,
          overflow:'hidden'
          }}>
  
  
  
       <div style={{background:'linear-gradient(90deg, rgba(255,214,174,1) 0%, rgba(255,236,209,1) 48%, rgba(255,243,207,1) 100%)'}}>
       <img  width={"100%"} height={"300px"} src='desk.png' alt='img'/>
    <div style={{
      textAlign:'center', padding:20
    }}>
      <img style={{width:150,marginRight:5,borderRadius:15}} src='flogo.png' alt='img'/>
      <h1 style={{margin:0,fontSize:28}}>Agromax Warehousing</h1>
      <div style={{margin:'5px 20px',fontWeight:400,fontSize:16}}>Elevating Growth, Safeguarding Investments - Your Trusted Partner in Professional Warehousing, Collateral Management, Commodity Finance & Support.</div>
    </div>
  </div>
      </header>
    )
  } else {
    return (
      <header className='header m0' style={{
          margin:'20px 8%',
          borderRadius:8,
          overflow:'hidden'
          }}>
       <div style={{position:"relative",background:'linear-gradient(90deg, rgba(255,214,174,1) 0%, rgba(255,236,209,1) 48%, rgba(255,243,207,1) 100%)'}}>
       <img  width={"100%"} height={"300px"} src='desk.png' alt='img'/>
    <div style={{
      padding:10,
      textAlign:'center'
    }}>
      <img style={{width:150,marginRight:5,borderRadius:15}} src='flogo.png' alt='img'/>
      <h1 style={{margin:0,fontSize:'28px'}}>Agromax Warehousing</h1>
      <h3 style={{margin:'5px 4%',fontWeight:400,fontSize:16}}>Elevating Growth, Safeguarding Investments - Your Trusted Partner in Professional Warehousing, Collateral Management, Commodity Finance & Support.</h3>
    </div>
  </div>
      </header>
    )
    
  }


  
}

export default Header
